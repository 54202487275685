import { Injectable } from '@angular/core';
import { Subject, Observable, throwError , empty, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment, API_URL, APP_SLUG, BASIC_HEADERS } from '../../environments/environment';
import * as _ from 'underscore/underscore';

@Injectable({
  providedIn: 'root'
})
export class OhnLanguageService {

	languageChanger : Subject<string> = new Subject();
  languageLoader : Subject<boolean> = new Subject();

	loadedLang : any = {}

	lang : any = {
		en : {
			language_name: 'English',
			locale: 'en',
			strings: {
				enterYourEmail : 'Enter your email address',
				enterYour : 'Enter your',
				emailAddress : 'email address',
				phoneNumber : 'phone number',
				emailPhoneRequired : 'Email Address or Phone Number required',
				isRequired : 'is required',
				isNotValid : 'is not valid',
				credentialsNotValid : 'Credentials are not valid',
				pleaseTryAgain : 'please try again',
				enterAuthorizationCode : 'Enter authorization code',
				generatingVerificationCode : 'Generating verification code',
				pleaseEnterVerificationCode : 'Please enter verification code',
				wrongVerificationCode : 'Wrong verification code, please try again',
				tryAgain : 'Try again',
				verificationCodeRequired : 'Verification Code is required',
				bookmark : 'Bookmark',
				saving : 'Saving',
				removeFromBookmarks : 'Remove from Bookmarks',
				youHaveFitbit : 'You have Fitbit trackable exercise on this step. Tracking data will be synced automatically.',
				startNoiseRecording : 'Start Noise Recording',
				stopNoiseRecording : 'Stop Noise Recording',
				recording : 'Recording...',
				saveNote : 'Save Note',
				typeHere : 'Type here...',
				deleteNote : 'Delete Note',
				areYouSure : 'Are you sure?',
				goBack : 'GO BACK',
				delete : 'Delete',
				completed : 'COMPLETED',
				start : 'Start',
				stop : 'Stop',
				left : 'Left',
				center : 'Center',
				right : 'Right',
				hasPrescribedParameters : 'Has Prescribed Parameters',
				prescriptionVaries : 'Prescription varies (allows to enter values right before scheduling event)',
				timeSeconds : 'Time (seconds)',
				targetHeartRate : 'Target Heart Rate',
				maxHeartrate : 'Max Heart Rate',
				time : 'Time',
				linkTitle : 'Link Title',
				linkUrl : 'Link URL',
				enableBookmark : 'Enable Bookmark',
				bookmarkTag : 'Bookmark Tag',
				imageAltText : 'Image Alt Text',
				imageUrl : 'Image URL',
				imageAlignment : 'Image Alignment',
				bookmarks : 'Bookmarks',
				youHaveNotAddedBookmarks : 'You have not added any bookmarks yet',
				previous : 'Previous',
				next : 'Next',
				done : 'Done',
				continue : 'Continue',
				manageMedications : 'Manage Medications',
				manageMeetings : 'Manage Meetings',
				workouts : 'Workouts',
				workout : 'Workout',
				fitbitWorkouts : 'Fitbit Workouts',
				fitbitWorkout : 'Fitbit Workout',
				surveys : 'Surveys',
				survey : 'Survey',
				medications : 'Medications',
				medication : 'Medication',
				meetings : 'Meetings',
				meeting : 'Meeting',
				sequences : 'Sequences',
				eventStartTime : 'Event Start Time',
				cadence : 'Cadence',
				speed : 'Speed',
				power : 'Power',
				heartRate : 'Heart Rate',
				gear : 'Gear',
				prescribedValue : 'Prescribed Value',
				medicationName : 'Medication Name',
				description : 'Description',
				dose : 'Dose',
				selectDateToTake : 'Select Days To Take Medication',
				intakeTimes : 'Intake Times',
				addTime : 'Add Time',
				startTakingDate : 'Start Taking Date',
				stopTakingDate : 'Stop Taking Date',
				meetingName : 'Meeting Name',
				meetingURL : 'Meeting URL',
				selectDaysForMeeting : 'Select Days For This Meeting',
				meetingTimes : 'Meeting Times',
				startVisitingDate : 'Start Visiting Date',
				stopVisitingDate : 'Stop Visiting Date',
				accept : 'ACCEPT',
				decline : 'DECLINE',
				dataRecord : 'Data Record',
				dataRecords : 'Data Records',
				data : 'Data',
				editEvent : 'Edit event',
				deleteEvent : 'Delete event',
				newEvent : 'New Event',
				enableFitbitDataSync : 'Enable Fitbit Data Sync',
				fitbitDataSyncEnabled : 'Fitbit Data Sync Enabled',
				syncHealthKitData : 'Sync Health Kit Data',
				downloadFitbitApp : 'Download Fitbit app here',
				devicePaired : 'Device paired',
				pairDevice : 'Pair Device',
				forgetDevice : 'Forget Device',
				history : 'History',
				date : 'Date',
				stage : 'Stage',
				seeStage : 'SEE STAGE',
				notes : 'Notes',
				save : 'Save',
				resetProfileData : 'RESET Profile and Onboarding Data',
				reset : 'RESET',
				tapResetToClear : 'Tap RESET to clear all the information entered into the app. This action cannot be undone. Tap KEEP DATA to go back.',
				keepData : 'KEEP DATA',
				settingsHasBeenSaved : 'Your settings have been saved.',
				settings : 'Settings',
				APP_OVERVIEW : 'Welcome to CONNECT!',
				loading : 'Loading...',
				stagingTool : 'Staging Tool',
				logout : 'Logout',
				closing : 'Closing',
				doYouWantToSaveTheResult : 'Do you want to save the result?',
				yes : 'Yes',
				no : 'No',
				loggingOut : 'Logging out',
				areYouSureYouWantToLogOut : 'Are you sure you want to log out?',
				cancel : 'Cancel',
        plsEnterPhoneEmail : 'Please enter your email address',  
        enterPhoneEmail : 'Enter your email',
				weWillSendYouConfirmationCode : 'We will send you a confirmation code',
        goodDay : 'Good Day!',
        goodEvening : 'Good Evening!',
        goodMorning : 'Good Morning!',
				enterCodeYouReceived : 'Enter code you received',
				weSentTo : 'We sent it to',
        notCorrect : 'Not correct?',   
        click : 'click',
        here : 'here',
        toReEnter : 'to re-enter'
			} 
		},
		es : {
			language_name: 'Español',
			locale: 'es',
			strings: {
				enterYourEmail : 'Ingrese su correo electrónico',
				enterYour : 'Enter your',
				emailAddress : 'email address',
				phoneNumber : 'phone number',
				emailPhoneRequired : 'Email Address or Phone Number required',
				isRequired : 'is required',
				isNotValid : 'is not valid',
				credentialsNotValid : 'Credentials are not valid',
				pleaseTryAgain : 'please try again',
				enterAuthorizationCode : 'Ingrese código de verificación',
				generatingVerificationCode : 'Generando código de verificación',
				pleaseEnterVerificationCode : 'Favor de ingresar su código de verificación',
				wrongVerificationCode : 'Código de verificación incorrecto. Favor de intentar de nuevo.',
				tryAgain : 'Favor de intentar de nuevo.',
				verificationCodeRequired : 'Un código de verificación es requerido',
				bookmark : 'Marcar',
				saving : 'Guardando',
				removeFromBookmarks : 'Elimine Marcador',
				youHaveFitbit : 'You have Fitbit trackable exercise on this step. Tracking data will be synced automatically.',
				startNoiseRecording : 'Start Noise Recording',
				stopNoiseRecording : 'Stop Noise Recording',
				recording : 'Recording...',
				saveNote : 'GUARDAR NOTA',
				typeHere : 'Escriba aquí...',
				deleteNote : 'Elimine Nota',
				areYouSure : '¿Está seguro?',
				goBack : 'REGRESAR',
				delete : 'ELIMINAR',
				completed : 'COMPLETED',
				start : 'Start',
				stop : 'Stop',
				left : 'Left',
				center : 'Center',
				right : 'Right',
				hasPrescribedParameters : 'Has Prescribed Parameters',
				prescriptionVaries : 'Prescription varies (allows to enter values right before scheduling event)',
				timeSeconds : 'Time (seconds)',
				targetHeartRate : 'Target Heart Rate',
				maxHeartrate : 'Max Heart Rate',
				time : 'Time',
				linkTitle : 'Link Title',
				linkUrl : 'Link URL',
				enableBookmark : 'Enable Bookmark',
				bookmarkTag : 'Bookmark Tag',
				imageAltText : 'Image Alt Text',
				imageUrl : 'Image URL',
				imageAlignment : 'Image Alignment',
				bookmarks : 'Marcadores',
				youHaveNotAddedBookmarks : 'Todavía no ha agregado marcadores.',
				previous : 'Anterior',
				next : 'Siguiente',
				done : 'Finalizado',
				continue : 'Continuar',
				manageMedications : 'Manage Medications',
				manageMeetings : 'Manage Meetings',
				workouts : 'Workouts',
				workout : 'Workout',
				fitbitWorkouts : 'Fitbit Workouts',
				fitbitWorkout : 'Fitbit Workout',
				surveys : 'Surveys',
				survey : 'Survey',
				medications : 'Medications',
				medication : 'Medication',
				meetings : 'Meetings',
				meeting : 'Meeting',
				sequences : 'Sequences',
				eventStartTime : 'Event Start Time',
				cadence : 'Cadence',
				speed : 'Speed',
				power : 'Power',
				heartRate : 'Heart Rate',
				gear : 'Gear',
				prescribedValue : 'Prescribed Value',
				medicationName : 'Medication Name',
				description : 'Description',
				dose : 'Dose',
				selectDateToTake : 'Select Days To Take Medication',
				intakeTimes : 'Intake Times',
				addTime : 'Add Time',
				startTakingDate : 'Start Taking Date',
				stopTakingDate : 'Stop Taking Date',
				meetingName : 'Meeting Name',
				meetingURL : 'Meeting URL',
				selectDaysForMeeting : 'Select Days For This Meeting',
				meetingTimes : 'Meeting Times',
				startVisitingDate : 'Start Visiting Date',
				stopVisitingDate : 'Stop Visiting Date',
				accept : 'ACCEPT',
				decline : 'DECLINE',
				dataRecord : 'Data Record',
				dataRecords : 'Data Records',
				data : 'Data',
				editEvent : 'Edit event',
				deleteEvent : 'Delete event',
				newEvent : 'New Event',
				enableFitbitDataSync : 'Enable Fitbit Data Sync',
				fitbitDataSyncEnabled : 'Fitbit Data Sync Enabled',
				syncHealthKitData : 'Sync Health Kit Data',
				downloadFitbitApp : 'Download Fitbit app here',
				devicePaired : 'Device paired',
				pairDevice : 'Pair Device',
				forgetDevice : 'Forget Device',
				history : 'Historia',
				date : 'Fecha',
				stage : 'Etapa',
				seeStage : 'VER ETAPA',
				notes : 'NOTAS',
				save : 'Guardar',
				resetProfileData : 'REINICIAR perfil y datos de incorporación',
				reset : 'REINICIAR',
				tapResetToClear : 'Toque REINICIAR para borrar toda la información ingresada en la aplicación. Esta acción no se puede deshacer. Toque CONSERVAR DATOS para volver.',
				keepData : 'CONSERVAR DATOS',
				settingsHasBeenSaved : 'Su configuración ha sido guardada.',
				settings : 'Configuraciones',
				APP_OVERVIEW : 'El propósito de esta aplicación es ayudar a los cuidadores a comprender las etapas funcionales de la demencia y de proveer enlaces a recursos útiles en cada etapa.',
				loading : 'Cargando...',
				stagingTool : 'Evaluación de etapas',
				logout : 'Cerrar Sesión',
				closing : 'Cerrando',
				doYouWantToSaveTheResult : '¿Quiere guardar el resultado?',
				yes : 'Sí',
				no : 'No',
				loggingOut : 'Cerrar sesión',
				areYouSureYouWantToLogOut : '¿Esta seguro que quiere cerrar la sesión?',
				cancel : 'CANCELAR',
        plsEnterPhoneEmail : 'Por favor, ingrese su correo electrónico',  
        enterPhoneEmail : 'Ingrese su correo electrónico',
        weWillSendYouConfirmationCode : 'Te enviaremos un código de confirmación',
        goodDay : '¡Buen Día!',
        goodEvening : '¡Buenas noches!',
        goodMorning : '¡Buen día!',
				enterCodeYouReceived : 'Ingrese el código que recibió',
				weSentTo : 'Lo enviamos a',
        notCorrect : '¿No es correcto?',   
        click : 'haga clic',
        here : 'aquí',
        toReEnter : 'para volver a ingresar el código'
			} 
		}
	}

	locale : string;

  constructor(
  	private http: HttpClient,
  ) {
  	let locale = localStorage.getItem('ohn-locale');
    this.locale = locale ? locale : 'en';

    this.loadedLang = this.lang;
    //this.init();
    //console.log(JSON.stringify(this.lang));
  }

  init() {
  	const langReq = this.http.get(API_URL+ '/localization/' + APP_SLUG)
		.pipe(
			map(res => {
				this.loadedLang = res
				//this.languageLoader.next(true);
			}),
			catchError(err => {
				console.log('Error loading localizations')
				return empty();
			})
    );
    langReq.subscribe()
  }

  getAvailableLocales(){
  	let languages: any[] = [];
  	const locales = _.keys(this.loadedLang);
  	locales.forEach(l=>{
  		languages.push({locale : l, name : this.loadedLang[l].language_name})
  	});
  	return languages;
  }

  setCurrentLocale(locale: string) {
  	this.locale = locale;
  	localStorage.setItem('ohn-locale', locale);
  	this.languageChanger.next(locale);
  }

  g(slug: string){
  	return this.lang[this.locale].strings[slug];
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser, OHNUserRole, OHNPermission, OHNPermissionDescription} from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { ModalController } from '@ionic/angular';

import { DEBUG_MODE, APP_SLUG } from '../../../environments/environment';

import * as _ from 'underscore/underscore';
import * as queryString from 'query-string';

@Component({
  selector: 'app-patient-assignment',
  templateUrl: './patient-assignment.component.html',
  styleUrls: ['./patient-assignment.component.scss'],
})
export class PatientAssignmentComponent implements OnInit {

  @Input() owner: OHNUser; // patient/video

  recipients: OHNUser[]; //coders
  filter: string = 'owner';
  assignedRecipients: any[];

  loading: boolean = true;

  constructor(
    public modalController: ModalController,
    private ohnApi: OhnApiService,
    private ohnService: OhnService
  ) { }

  ngOnInit() {
    this.loading = true;
    DEBUG_MODE && console.log(this.owner);
    this.getAssignmentsFiltered(this.owner.smart_contract);
  }


  getAssignmentsFiltered(smart_contract: string) {
    this.ohnApi.getAssignmentsFiltered(this.filter, smart_contract).subscribe(assignments => {
      DEBUG_MODE && console.log("getAssignmentsFiltered", assignments);
      this.assignedRecipients = assignments;
      this.getPossibleRecipients();
    });
  }

  getPossibleRecipients() {
    let filterObject: any = {
      roles: ["patientspherex_practitioner"]
    };

    let filterQuery = queryString.stringify(filterObject, {arrayFormat: 'bracket'});
    let self = this;

    this.ohnApi.getUserListFiltered(filterQuery).subscribe(coders => {
      DEBUG_MODE && console.log("getUserListFiltered", coders);
      this.recipients = _.map(coders, (c)=>{
        c.assignment = (self.assignedRecipients) && (self.assignedRecipients.find(a => a.recipient_smart_contract == c.smart_contract));
        return c;
      });
      this.loading = false;
    });
  }

  setAssignment(recipient: any) {
    DEBUG_MODE && console.log("setAssignment", recipient);
    
    let data = {
      app_slug: APP_SLUG,
      owner_smart_contract: this.owner.smart_contract,
      owner_username: this.owner.username,
      recipient_smart_contract: recipient.smart_contract,
      recipient_username: recipient.username,
      recipient_name: recipient.name,
      status_code: "0",
      state_id: null,
      date_assigned: Date.now(),
      date_coded: null,
      date_approved: null
    }

    this.ohnApi.setAssignments(data).subscribe(state => {
      DEBUG_MODE && console.log("setAssignment", state)
      recipient.assignment = state;
    });

  }

  deleteAssignment(recipient: any) {
    this.ohnApi.deleteAssignments(recipient.assignment.id).subscribe(state => {
      DEBUG_MODE && console.log("deleteAssignment", state);
      recipient.assignment = undefined;
    });
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
